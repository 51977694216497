<template>
  <img
    v-if="visa"
    src="assets/payment-icons/min/flat/visa.svg"
  >
  <img
    v-else-if="mastercard"
    src="assets/payment-icons/min/flat/mastercard.svg"
  >
  <img
    v-else-if="amex"
    src="assets/payment-icons/min/flat/amex.svg"
  >
  <img
    v-else-if="diners"
    src="assets/payment-icons/min/flat/diners.svg"
  >
  <img
    v-else-if="discover"
    src="assets/payment-icons/min/flat/discover.svg"
  >
  <img
    v-else-if="jcb"
    src="assets/payment-icons/min/flat/jcb.svg"
  >
  <img
    v-else-if="maestro"
    src="assets/payment-icons/min/flat/maestro.svg"
  >
</template>

<script>
export default {
  props: {
    type: String,
    method: String
  },
  data () {
    return {
      visa: false,
      mastercard: false,
      amex: false,
      diners: false,
      discover: false,
      jcb: false,
      maestro: false
    }
  },
  watch: {
    type: {
      handler: 'check',
      immediate: true
    },
    method: {
      handler: 'check',
      immediate: true
    }
  },
  methods: {
    /**
     * We need this as the current API endpoint returns the card name from
     * https://test.sagepay.com/documentation/#test-card-details rather
     * than the type.
     */
    check (value, old) {
      if (value) {
        this.visa = false
        this.mastercard = false
        this.amex = false
        this.diners = false
        this.discover = false
        this.jcb = false
        this.maestro = false

        if (value.startsWith('Visa')) {
          this.visa = true
          return true
        }
        if (value.startsWith('Debit') || value.startsWith('Master')) {
          this.mastercard = true
          return true
        }
        if (value.startsWith('Maestro')) {
          this.maestro = true
          return true
        }
        if (value.startsWith('American')) {
          this.amex = true
          return true
        }
        if (value.startsWith('Diners') || value.startsWith('Discover')) {
          this.discover = true
          return true
        }
        if (value.startsWith('JCB')) {
          this.jsb = true
          return true
        }
      }
    }
  }
}
</script>
